<template>
<v-app>
    <v-container>
    <v-row v-if="categories.length > 1">
      <v-tabs
        centered
        class="ml-n9"
        color="grey darken-1"
      >
        <v-tabs-slider color="primary" />
        <v-tab
          v-for="(link, index) in links"
          :key="link"
          @click="_tab_selected(index)"
        >
          {{ link }}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row v-if="selectedCategory!=null">
      <v-col
        cols="12"
        :sm="asuulga!=null&&asuulga.showWithSlide ? 0 : 2"
      />
      <v-col
        v-if="asuulga.showWithSlide"
        cols="12"
        sm="8"
      >
        <v-card class="mb-4">
          <v-container>
            <v-row class="px-4 mt-4">
              <H2>{{ asuulga.name }}</H2>
              <p class="mt-1">
                {{ asuulga.description }}
              </p>
            </v-row>
            <v-row
              v-if="asuulga.slideUrl != null && asuulga.slideUrl != undefined"
            >
              <v-col
                cols="12"
                style="width:100%"
                v-html="_getIFrameUrl(asuulga)"
              />
            </v-row>
          </v-container>
        </v-card>

        <!-- <v-container v-if="asuulga!=null&&asuulga.showWithSlide==false">
          <view-question
            v-for="(question, qindex) in selectedCategory.questions"
            :key="qindex"
            :question="question"
            :qindex="qindex"
            :category="selectedCategory"
            :asuulga-id="asuulgaId"
          />
        </v-container> -->
      </v-col>
      <v-col v-else />

      <v-col
        cols="12"
        :sm="asuulga!=null&&asuulga.showWithSlide ? 4 : 0"
      >
        <v-card>
          <y-question-vertical-panel :asuulga-id="asuulgaId" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</v-app>
</template>

<script>
  import YQuestionVerticalPanel from './YQuestionVerticalPanel'

  const fb = require('../../firebaseConfig.js')
  export default {
    components: {
      YQuestionVerticalPanel,
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        // return value.charAt(0).toUpperCase() + value.slice(1)
        return value.toUpperCase()
      },
    },
    props: {
      asuulgaId: {
        type: String,
        required: false,
      },
    },

    data: () => ({
      asuulga: null,
      selectedCategory: null,
      links: ['Questions', 'Participants', 'Responses'],
      categories: [],
    }),
    created() {
      this._findCategories()
    // if (this.categories != null && this.categories.length > 0)
    //             this.selectedCategory = this.categories[0];
    },
    methods: {
      _getIFrameUrl(asuulga) {
        console.log(asuulga.slideUrl)
        return (
          "<iframe frameborder='0' width='100%' height='569' allowfullscreen='true' mozallowfullscreen='true' webkitallowfullscreen='true'" +
          asuulga.slideUrl +
          ' </iframe>'
        )
      },
      _viewQuestion() {
        console.log('xxx')
      },
      _tab_selected(index) {
        this.selectedTabIndex = index
      },

      _getAnswers(question, ref) {
        console.log(ref.path)
        if (question.answers == null || question.answers === undefined) { question.answers = [] }
        ref
          .collection('answers')
          .orderBy('createdAt', 'asc')
          .onSnapshot((querySnapshot) => {
            question.answers = []
            querySnapshot.forEach((doc) => {
              const answer = doc.data()
              answer.id = doc.id
              question.answers.push(answer)
            })
          })
      },
      _getQuestionsOfCategory(cat) {
        console.log(
          'asuulga/' + this.asuulgaId + '/categories/' + cat.id + '/questions',
        )
        fb.db
          .collection('asuulga/' + this.asuulgaId + '/categories/' + cat.id + '/questions')
          .orderBy('createdAt', 'asc')
          .onSnapshot((querySnapshot) => {
            cat.questions = []
            querySnapshot.forEach((doc) => {
              const object = doc.data()
              object.id = doc.id
              object.category = cat.name
              object.statData = null
              object.datacollection = null

              console.log(doc.ref)
              if (
                object.divided === false &&
                object.group != null &&
                object.group !== undefined
              ) {
              //
              } else {
                if (object.divided === true) {
                  object.subquestions = []
                  this._getSubquestions(
                    object,
                    fb.db.doc(
                      'asuulga/' + this.asuulgaId + '/categories/' + cat.id,
                    ),
                    doc.ref,
                  )
                } else {
                  if (object.answertype === true) {
                    object.useranswers = []
                  // this._getQuestionAnswers(object, doc.ref);
                  } else {
                    object.answers = []
                    console.log(doc.ref)
                    this._getAnswers(object, doc.ref)
                  }
                }
                cat.questions.push(object)
              }
            })
          })
      },
      async _findCategories() {
        var _this = this
        var asuulga = fb.db.collection('asuulga').doc(this.asuulgaId)
        asuulga
          .get()
          .then(function (doc) {
            if (doc.exists) {
              _this.asuulga = doc.data()
              console.log('Document data:', doc.data())
            } else {
              // doc.data() will be undefined in this case
              console.log('No such document!')
            }
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })

        fb.db
          .collection('asuulga/' + this.asuulgaId + '/categories')
          .orderBy('createdAt', 'asc')
          .onSnapshot((querySnapshot) => {
            this.categories = []
            querySnapshot.forEach((catDoc) => {
              const cat = catDoc.data()
              cat.id = catDoc.id
              cat.questions = []
              this._getQuestionsOfCategory(cat)
              this.categories.push(cat)
              if (this.categories.length === 1) {
                this.selectedCategory = this.categories[0]
              }
            })
          })
      },
    },
  }
</script>
<style scoped>
.punch-viewer-content{
  bottom: 0px;
}
.v-main {
  background-color: #f0ebf7;
}
::-webkit-scrollbar {
width: 8px;

}

::-webkit-scrollbar-track {

    border-radius: 0px;
    background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #f0ebf7;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
    height: 5px;

}
::-webkit-scrollbar-thumb:hover {
background: hotpink;
}
::-webkit-scrollbar-button {
}
</style>
